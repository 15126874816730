<template>
  <v-speed-dial
    top
    right
    absolute
    direction="left"
    transition="slide-x-transition"
    small
  >
    <template v-slot:activator>
      <v-btn dark fab @click="create">
        <v-icon>
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
  </v-speed-dial>
</template>

<script>
export default {
  name: 'CreateButton',
  props: {
    createFunction: { type: Function, required: true },
    icon: { type: String },
  },

  data() {
    return {
      snackbar: {},
    }
  },

  methods: {
    create() {
      this.createFunction()
    },
  },

  created() {},
}
</script>
